import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './Corporate.css'; 
import { ContactUs } from '../components/CorporateForm';
import img1 from '../assets/images/Corporate/Cp2.jpg';
import { Box,  Typography, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Corporate = () => {

    const [topMargin, setTopMargin] = useState('100px');
    const location = useLocation();

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const scrollToForm = () => {
        const formElement = document.querySelector('.corporate-booking-form');
        if (formElement) {
            formElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
    <div style={{ marginTop: topMargin }}>
        <Header />
        <Box sx={{ backgroundColor: '#2D3C34', pb: 8 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', mb: 6, p: 4, backgroundColor: '#F3E8DD', borderRadius: '12px', boxShadow: 3 }}>
                <Box className='corporate-booking-image' sx={{ flex: 1, mr: { md: 4 }, overflow: 'hidden', borderRadius: '8px' }}>
                    <img src={img1} alt='' style={{ width: 'auto', height: '100%', objectFit: 'cover', borderRadius: '8px' }} />
                </Box>
                <Box sx={{ flex: 1, textAlign: 'center' }}>
                    <Typography variant="h3" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#333333', mb: 3, fontSize: 60 }}>
                        Social Group Golf Day
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#4e4e4e', lineHeight: 1.7, mt: 4, fontSize: 25, width: '80%', mx: 'auto' }}>
                        The Yering Meadows Golf Club is the ideal venue to host your next corporate golf day. Our professional and friendly staff will tailor an event to ensure a successful and enjoyable day for all involved.
                        <br/><br/>
                        The availability of our course and clubhouse facilities can vary depending on the season and other scheduled events but the preferred days are Mondays, Thursdays or Fridays with both morning and afternoon rounds possible. Yering Meadows is proud to also offer over 110 tee times on Saturdays and over 230 on Sundays for social golf clubs.
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#4e4e4e', lineHeight: 1.7, mt: 4, fontSize: 25, width: '80%', mx: 'auto' }}>
                        All players are expected to meet the Club's dress expectations which can be viewed in full
                        <br/>
                        <a href="/dress-code" style={{ color: '#B08968', textDecoration: 'none' }}>View Dress Code</a>
                    </Typography>
                    <Button onClick={scrollToForm} variant="contained" sx={{ backgroundColor: '#B08968', mt: 4, '&:hover': { backgroundColor: '#A0785A' }, borderRadius: '8px', px: 4, py: 1 }}>
                        Click here to book
                    </Button>
                </Box>
            </Box>
            <Box sx={{ mt: 8, p: 6, backgroundColor: '#F3E8DD', borderRadius: '12px', boxShadow: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#333333', mb: 3 }}>
                    Eligibility and Benefits
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#4e4e4e', lineHeight: 1.7 }}>
                    <ul>
                        <li><strong>Group Size:</strong> Bookings are available for groups of 16 - 130 players.</li>
                        <li><strong>Advanced Booking:</strong> Secure your tee times up to 12 months in advance.</li>
                        <li><strong>Smaller Groups:</strong> Groups of less than 16 players are subject to the standard online booking procedure, based on availability.</li>
                    </ul>
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#333333', mb: 3, mt: 6 }}>
                    Booking Process
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#4e4e4e', lineHeight: 1.7 }}>
                    <ul>
                        <li><strong>Deposit Payment:</strong>
                            <ul>
                                <li>A 25% deposit is required to confirm your booking.</li>
                                <li>The deposit is non-refundable in case of cancellation or reduction in group size.</li>
                            </ul>
                        </li>
                        <li><strong>Invoice and Final Payment:</strong>
                            <ul>
                                <li>An invoice for the remaining amount will be sent 3 weeks prior to the event date.</li>
                                <li>The remaining balance is due 2 weeks prior to the event date.</li>
                            </ul>
                        </li>
                        <li><strong>Final Confirmation:</strong>
                            <ul>
                                <li>Your booking will be confirmed once the deposit is received.</li>
                                <li>A confirmation email will be sent to the group contact.</li>
                            </ul>
                        </li>
                    </ul>
                </Typography>
            </Box>
            <Box className='corporate-booking-form' sx={{ backgroundColor: '#F3E8DD', borderRadius: '12px', p: 6, boxShadow: 3, textAlign: 'center', mt: 8 }}>
                <Typography variant="h4" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#638B74', textAlign: 'center', mb: 3 }}>
                    Ready to Book?
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#2e2e2e', mb: 4 }}>
                    To make an enquiry, please fill out the form below with your details and preferred booking date. We will get back to you shortly to confirm availability and assist with your booking.
                </Typography>
                <ContactUs />
            </Box>
            <Box sx={{ mt: 8, p: 6, backgroundColor: '#F3E8DD', borderRadius: '12px', boxShadow: 3,  mb: -5 }}>
                <Typography variant="h5" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#333333', mb: 0 }}>
                    Policies and Important Information
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#4e4e4e', lineHeight: 1.7 }}>
                    <ul>
                        <li><strong>Cancellations:</strong>
                            <ul>
                                <li>Can be made by emailing ops@yeringmeadows.com.au.</li>
                                <li>Refunds are available if cancellation is requested at least 7 days before the scheduled group booking.</li>
                                <li>Cancellations within 7 days of a confirmed tee time are non-refundable, and no credit notes will be issued.</li>
                                <li>Credit notes will be issued if the golf course is deemed unplayable on the day. These must be used within 90 days from the date of issue.</li>
                            </ul>
                        </li>
                        <li><strong>Full Payment and Final Numbers:</strong>
                            <ul>
                                <li>Required 2 weeks prior to the event date.</li>
                                <li>Changes to bookings may be subject to availability and additional charges.</li>
                            </ul>
                        </li>
                        <li><strong>Course Selection:</strong>
                            <ul>
                                <li>Subject to availability.</li>
                                <li>Course allocations may change due to maintenance at any time.</li>
                            </ul>
                        </li>
                        <li><strong>Golf Carts and Rental Equipment:</strong>
                            <ul>
                                <li>Must be pre-booked and paid for if required.</li>
                            </ul>
                        </li>
                    </ul>
                </Typography>
            </Box>
        </Box>
        <Footer />
    </div>
  );
};

export default Corporate;