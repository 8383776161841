import React, { useState, useEffect } from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './ClubNews.css'; // Assuming CSS file is in the same directory
import ParallaxBanner1 from '../components/Banner1';

const ClubNews = () => {

    const [topMargin, setTopMargin] = useState(0);

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(headerHeight);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

  return (
    <div className="clubnews-page">
        <Header />
        
        <div className="clubnews-container" style={{ marginTop: `${topMargin}px`}}>
                <ParallaxBanner1/>
                
        </div>
        <Footer />
    </div>
  );
};

export default ClubNews;
