import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Box, Grid, Container, Paper, Checkbox, FormControlLabel } from '@mui/material';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

export const ContactUs = () => {
    const form = useRef();
    const [formValues, setFormValues] = useState({
        name: '',
        phone: '',
        email: '',
        groupName: '',
        numberOfPlayers: '',
        preferredDate: '',
        alternativeDate: '',
        teeTime: '',
        golfCarts: '',
        rentalEquipment: '',
        cateringInterested: false,
        cateringOptions: '',
        additionalActivities: '',
        comments: '',
        agreement: false
    });

    const australianPhonePattern = /^(?:\+61|0)[2-478](?:[ -]?[0-9]){8}$/;

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formValues.email)) {
            alert("Please enter a valid email address.");
            return;
        }

        if (!australianPhonePattern.test(formValues.phone)) {
            alert("Phone number should be a valid Australian phone number.");
            return;
        }

        if (!formValues.agreement) {
            alert("You must agree to the terms and conditions before submitting.");
            return;
        }

        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_OPS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_GOLFDAY_EMAIL_TEMPLATES,
            form.current,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        ).then((result) => {
            console.log(result.text);
            Swal.fire({
                title: 'Enquiry Created!',
                text: 'Notice Your booking has not been confirmed yet. A 25% deposit is required to secure your booking.',
                icon: 'success',
                confirmButtonText: 'OK'
            });
            setFormValues({
                name: '',
                phone: '',
                email: '',
                groupName: '',
                numberOfPlayers: '',
                preferredDate: '',
                alternativeDate: '',
                teeTime: '',
                golfCarts: '',
                rentalEquipment: '',
                cateringInterested: false,
                cateringOptions: '',
                additionalActivities: '',
                comments: '',
                agreement: false
            });
        }, (error) => {
            console.log(error.text);
            Swal.fire({
                title: 'Error!',
                text: 'There was an issue with your booking. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormValues({ ...formValues, [name]: checked });
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h3" gutterBottom sx={{ fontFamily: 'GTSD bold', color: '#638B74', textAlign: 'center', mb: 3 }}>
                    Social Group Golf Day Enquiry
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontFamily: 'AP Pro Medium', color: '#2e2e2e', mb: 3 }}>
                    Planning a golf outing with a group? Yering Meadows Golf Club is the perfect course for your social group to play at. Our 27-hole layout means we can accommodate group bookings every day of the week including Saturday and Sunday throughout the year. Our social group booking process ensures you can secure your preferred tee times and enjoy a fantastic day on the course.
                </Typography>
                <Box component="form" ref={form} onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                value={formValues.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="phone"
                                label="Phone Number"
                                name="phone"
                                value={formValues.phone}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="groupName"
                                label="Group Name/Company Name"
                                name="groupName"
                                value={formValues.groupName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="numberOfPlayers"
                                label="Number of Players (Min. 16)"
                                name="numberOfPlayers"
                                type="number"
                                value={formValues.numberOfPlayers}
                                onChange={handleChange}
                                inputProps={{ min: 16, max: 140 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="preferredDate"
                                label="Preferred Date"
                                name="preferredDate"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={formValues.preferredDate}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="alternativeDate"
                                label="Alternative Date"
                                name="alternativeDate"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={formValues.alternativeDate}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="teeTime"
                                label="Preferred Tee Time"
                                name="teeTime"
                                value={formValues.teeTime}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="golfCarts"
                                label="Number of Golf Carts Required"
                                name="golfCarts"
                                type="number"
                                value={formValues.golfCarts}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="rentalEquipment"
                                label="Number of Rental Equipment Sets Required"
                                name="rentalEquipment"
                                type="number"
                                value={formValues.rentalEquipment}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="cateringInterested"
                                        checked={formValues.cateringInterested}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Interested in Catering Options?"
                            />
                        </Grid>
                        {formValues.cateringInterested && (
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="catering-options-label">Catering Options</InputLabel>
                                    <Select
                                        labelId="catering-options-label"
                                        id="cateringOptions"
                                        name="cateringOptions"
                                        value={formValues.cateringOptions}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="breakfast">Breakfast</MenuItem>
                                        <MenuItem value="lunch">Lunch</MenuItem>
                                        <MenuItem value="snacks">Snacks</MenuItem>
                                        <MenuItem value="drinks">Drinks</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    fullWidth
                                    id="cateringComments"
                                    label="Catering Comments"
                                    name="cateringComments"
                                    multiline
                                    rows={2}
                                    value={formValues.cateringComments || ''}
                                    onChange={handleChange}
                                    sx={{ mt: 2 }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="additional-activities-label">Additional Activities</InputLabel>
                                <Select
                                    labelId="additional-activities-label"
                                    id="additionalActivities"
                                    name="additionalActivities"
                                    value={formValues.additionalActivities}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="golf clinic">Golf Clinic</MenuItem>
                                    <MenuItem value="lessons">Lessons</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="comments"
                                label="Special Requests and Comments"
                                name="comments"
                                multiline
                                rows={4}
                                value={formValues.comments}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="agreement"
                                        checked={formValues.agreement}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="I have read and agree to the Yering Meadows Golf Club's terms and conditions for group bookings."
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 4, backgroundColor: '#2D3C34', '&:hover': { backgroundColor: '#92917d' } }}>
                        Submit Enquiry
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};
