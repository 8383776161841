import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import './Golf.css';
import EastIcon from '@mui/icons-material/East';

const Golf = () => {
    const [topMargin, setTopMargin] = useState('100px'); // Adjust this value as per the initial height of your header

    useEffect(() => {
        const updateTopMargin = () => {
            const headerHeight = document.querySelector('.header-container').offsetHeight;
            setTopMargin(`${headerHeight}px`);
        };

        updateTopMargin();
        window.addEventListener('resize', updateTopMargin);

        return () => {
            window.removeEventListener('resize', updateTopMargin);
        };
    }, []);

    return (
        <div style={{ marginTop: topMargin, backgroundColor: '#FFFCF9' }}>
            <Header />
            <div className="course-content-container">
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
                    <div className="coursesTitle">
                        <h1>The Courses</h1>
                    </div>
                    <div className="course-content">
                        <div className="course-image-container">
                            <img src="/images/content/content1.jpg" alt="Course 1" className="course-image" />
                            <img src="/images/content/content2.jpg" alt="Course 2" className="course-image" />
                            <img src="/images/content/content3.jpg" alt="Course 3" className="course-image" />
                        </div>
                        <div className="coursesContent">
                            <p>
                                Discover the beauty of Yering Meadows, situated on 330 acres in Victoria’s picturesque Yarra Valley Region. 
                                Designed by the renowned Ross Watson, our 27-hole layout features pristine Legend Couch fairways and Bent Grass greens. 
                                The first 18 holes opened to players in June 2008, with an additional nine holes (19-27) debuting the following year.
                            </p>
                            <p>
                                Our courses are arranged into three unique 18-hole configurations: Nursery, Valley, and Homestead. 
                                Each course seamlessly integrates into the natural landscape, enhancing the beauty of the countryside. 
                                Watson’s thoughtfully placed ponds and wetlands are key architectural features, presenting strategic challenges on nearly 
                                every hole in the first 18, with a more subtle presence across holes 19-27.
                            </p>
                            <p>
                                Golfers will find our large green complexes both inviting and challenging, with varying elevations to test their skills. 
                                Experience the perfect blend of natural beauty and strategic design at Yering Meadows.
                            </p>
                            <h2>Green Fees</h2>
                            <ul>
                                <li>Weekday Green Fee – 18 Hole: $60</li>
                                <li>Weekend / Public Holiday Green Fee – 18 Hole: $70</li>
                                <li>Weekday/ Weekend/ Public Holiday Green Fee – 9 Hole: $45</li>
                                <li>Cart Hire – 18 Hole: $50</li>
                                <li>Cart Hire – 9 Hole: $35</li>
                            </ul>
                        </div>
                    </div>
                    <div className="CourseOverviewLink">
                            <p>Explore Course Overview</p>
                            <div className='CourseOverviewArrow'>
                                <a href="/golf/golfcourse">
                                    <motion.div
                                        initial={{ x: 0 }} 
                                        whileHover={{ x: 10 }}
                                        transition={{ 
                                            duration: 0.3, 
                                            yoyo: Infinity, 
                                            ease: "easeInOut" 
                                        }}
                                    >
                                        <EastIcon/>
                                    </motion.div>
                                </a>
                            </div>
                        </div>
                </motion.div>
            </div>
            <Footer />
        </div>
    );
};

export default Golf;
